import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import 'firebase/firestore';
import '../style.scss';

class Cards extends React.PureComponent {
  render() {
    const { tableData } = this.props;
    return (
      <React.Fragment>
        {tableData.map((inventoryItem) => {
          const days = moment()
            .to(inventoryItem.endDate.toDate())
            .split(' ')[1];
          return (
            <div className="col-lg-3 col-md-4 col-sm-12" key={inventoryItem.id}>
              <div className="card">
                <div className="card-title text-center text-capitalize">
                  {inventoryItem.productName}
                </div>
                <div>
                  <div className="mrp-value card-subtitle">&#8377; {inventoryItem.mrp}</div>

                  <div className="offer-label">
                    {inventoryItem.offerType === 'scheme' ? 'SCHEME:' : 'DISCOUNT:'}
                  </div>
                  <div className="offer">
                    {inventoryItem.offerType === 'scheme' ? (
                      inventoryItem.scheme
                    ) : (
                      <div className="discount-value">{` ${inventoryItem.discount}%`}</div>
                    )}
                  </div>
                </div>

                <div className="text-center" style={{ marginTop: '20px' }}>
                  <div className="offer-end-date">
                    <span style={{ fontWeight: '400', color: '#94979a', fontSize: '14px' }}>
                      Valid Till:
                    </span>
                    <br />
                    <span className="offer-end-value">
                      {moment(inventoryItem.endDate.toDate()).format('ll')}
                    </span>
                  </div>
                  <div>
                    {days <= 10 ? (
                      <div className="offer-expiry">
                        {`Hurry Up!! Offer expires ${moment().to(inventoryItem.endDate.toDate())}`}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </React.Fragment>
    );
  }
}

Cards.propTypes = {
  tableData: PropTypes.object.isRequired,
};

export default Cards;
