import React from 'react';
import './Loader.scss';

const Loader = () => {
  return (
    <div className="spinner">
      <div className="dot1" />
      <div className="dot2" />
    </div>
  );
};

export default Loader;
