import React from 'react';
import 'firebase/firestore';
import moment from 'moment';
import { hot } from 'react-hot-loader/root';
import { TiLocation } from 'react-icons/ti';
import { FaPhone, FaSearch } from 'react-icons/fa';
import Cards from './Components/Cards';
import Loader from './Loader/Loader';
import { firebaseDb } from './utils/firebase';

class App extends React.PureComponent {
  state = {
    tableData: {},
    isLoading: true,
    searchedItem: null,
    text: '',
  };

  unsubscribeInventoryItemsListener = null;

  componentDidMount() {
    this.unsubscribeInventoryItemsListener = firebaseDb
      .collection('inventoryItems')
      .where('isActive', '==', true)
      .onSnapshot((querySnapshot) => {
        const data = [];
        const today = moment();
        querySnapshot.forEach((doc) => {
          const docData = doc.data();
          if (
            today.isBetween(moment(docData.startDate.toDate()), moment(docData.endDate.toDate()))
          ) {
            data.push({ id: doc.id, ...docData });
          }
        });
        this.setState({ tableData: data, isLoading: false });
      });
  }

  componentWillUnmount() {
    this.unsubscribeInventoryItemsListener();
  }

  stopLoading = () => {
    this.setState({ isLoading: false });
  };

  handleSearch = (ev) => {
    const { value } = ev.target;
    console.log('value', value);
    const { tableData } = this.state;
    const array = tableData.filter((data) => {
      console.log('data', data.productName.indexOf(value));
      if (data.productName.indexOf(value) > -1) {
        return data;
      }
      return null;
    });
    this.setState({ text: value, searchedItem: array });
  };

  render() {
    const { isLoading, tableData, text, searchedItem } = this.state;
    console.log('searchedItem', searchedItem);
    return (
      <div>
        {isLoading ? (
          <Loader />
        ) : (
          <div className="main-container">
            <div className="header-container">
              <div id="header-row">
                <div className="container">
                  <div className="row justify-content-between">
                    <div
                      style={{ display: 'flex', alignItems: 'center' }}
                      className="col-12 col-md-auto"
                      id="header-name"
                    >
                      <div>
                        <h3>SAMDANI PHARMA</h3>
                      </div>
                    </div>
                    <div className="col-12 col-md-auto">
                      <div id="header-info">
                        <span id="address">
                          <TiLocation />
                          <span className="header-address">DAWA BAZAR, JALNA</span>
                        </span>
                        <span id="number">
                          <FaPhone />
                          <span className="header-number">8999074472</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="quotation">
              <h2>Offers on Products</h2>
            </div>
            <div className="container search-container">
              <div className="search">
                <input
                  type="text"
                  placeholder="Search"
                  style={{ paddingLeft: '10px', borderRadius: '10px 0px 0px 10px', border: 'none' }}
                  onChange={this.handleSearch}
                  value={text}
                />
                <FaSearch
                  style={{
                    width: '35px',
                    height: '35px',
                    padding: '10px',
                    borderLeft: 'none',
                    borderRadius: '0px 10px 10px 0px',
                    backgroundColor: '#b7b2b2',
                    border: 'none',
                    color: 'white',
                  }}
                />
              </div>
            </div>
            <div className="container card-container" style={{ paddingTop: '15px' }}>
              <div className="row" id="card-row">
                <Cards
                  stopLoading={this.stopLoading}
                  tableData={searchedItem !== null ? searchedItem : tableData}
                />
              </div>
            </div>
          </div>
        )}
        <a
          href="tel:+918999074472"
          style={{
            color: 'white',
            boxShadow: '0px -7px 6px -3px rgba(0,0,0,.5)',
            padding: '12px',
          }}
          className="call fixed-bottom d-md-none btn btn-info"
        >
          <FaPhone style={{ marginRight: '10px' }} />
          Call Me
        </a>
      </div>
    );
  }
}

export default hot(App);
