import * as firebase from 'firebase/app';
import 'firebase/firestore';

const firebaseConfigDev = {
  apiKey: 'AIzaSyCVHxX9j9bXYQDWuY8XoKOYDLyhrpVD-tw',
  authDomain: 'ecp23-backend-dev.firebaseapp.com',
  databaseURL: 'https://ecp23-backend-dev.firebaseio.com',
  projectId: 'ecp23-backend-dev',
  storageBucket: 'ecp23-backend-dev.appspot.com',
  messagingSenderId: '631575059938',
  appId: '1:631575059938:web:3fa5f4e51826abd7',
};

const firebaseConfigProd = {
  apiKey: 'AIzaSyCmV-fZmsI0ZJHAtm1C3VwJaBvsksJumYo',
  authDomain: 'ecp23-backend-prod.firebaseapp.com',
  databaseURL: 'https://ecp23-backend-prod.firebaseio.com',
  projectId: 'ecp23-backend-prod',
  storageBucket: '',
  messagingSenderId: '624164385514',
  appId: '1:624164385514:web:c3428879adfa23ad',
};

const firebaseApp = firebase.initializeApp(
  process.env.NODE_ENV === 'production' ? firebaseConfigProd : firebaseConfigDev,
);

export const firebaseDb = firebase.firestore();

export default firebaseApp;
